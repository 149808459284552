import React, { useEffect, useRef } from 'react';
import backgroundVideo from '../Videos/backgroundVideo.mp4';
import '../CSS/BackGroundVid.css'

function BackGroundVideo() {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.load();
      const playPromise = videoElement.play();

      // Catch any errors that occur during playback
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.error('Video playback failed:', error);
        });
      }
    }

    const handleTouchStart = () => {
      if (videoRef.current && videoRef.current.paused) {
        videoRef.current.play();
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (videoRef.current && videoRef.current.paused) {
          videoRef.current.play();
        }
      }
    };

    window.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);



  return (
    <div style={{ height: '100%', width: '100%' }}>
      <video
        ref={videoRef}
        src={backgroundVideo}
        autoPlay
        muted
        loop
        playsInline
        preload='auto'
        style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            minHeight: '100vh', // Add this line
        }}
      />
      <div className="title">
        <div className="left">
            <div className="large-text">Artisans plaquistes</div>
            <div className="small-text">en</div>
            <div className="large-text">
                <span className="charente">Charente</span>
                <span className="maritime">-Maritime</span>
            </div>
            <div className="small-text">à</div>
            <div className="large-text">
                <span className="la">La</span>
                <span className="rochelle"> Rochelle</span>
            </div>
            <div className="small-text">et sur</div>
            <div className="large-text">l'Île de Ré</div>
        </div>
                <div className="right">
                  <p>
                    Les travaux de plâtre&shy;rie en Charente-maritime à La Roche&shy;lle et sur l'Île de Ré
                    donner&shy;ont une nouv&shy;elle dimens&shy;ion à votre mais&shy;on, apparte&shy;ment ou local commer&shy;cial.
                    Que ce soi&shy;t pour une réno&shy;vation, une constru&shy;ction ou un réamén&shy;agement, les fini&shy;tions
                    choi&shy;sies peuvent complè&shy;tement changer l'esthét&shy;ique de votre es&shy;pace de vie. Notre
                    équ&shy;ipe de professio&shy;nnels de la plâtrerie en Charente-maritime à La Roch&shy;elle et sur
                    l'Île de Ré vous ai&shy;de à choi&shy;sir la fini&shy;tion idéa&shy;le pour vot&shy;re pro&shy;jet. Contactez-nous
                    pour des trav&shy;aux de plât&shy;rerie réus&shy;sis.
                  </p>
                </div>
            </div>
        </div>
    );
}

export default BackGroundVideo;

    
    
    