import React from 'react';
import '../CSS/UpperFooter.css';

const UpperFooter = () => (
  <footer className="UpperFooter">
    <div className="UpperFooter-container">
      <div className="UpperFooter-left">
        Artisans plaquistes intervenant en Charente-maritime à La Rochelle et sur l'Île de Ré.
      </div>
      <div className="UpperFooter-separator"></div>
      <div className="UpperFooter-right">
        <div className="UpperFooter-right-title">Une question ? Nous sommes à votre écoute :</div>
        <div>Tél : 06.24.82.68.06</div>
        <div>Du Lundi au Vendredi, de 8h00 à 19h00</div>
        <div>Le Samedi, de 8h00 à 12h00</div>
      </div>
    </div>
  </footer>
);

export default UpperFooter;
